import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = [
    "exportingClinic",
    "patientSelectionDropdown",
    "panelledPatientsSelectionDropdown",
    "employeeSelectionSection", 
    "patientListSelectionSection",
    "employeeDropdownOptions",
    "patientListDropdownOptions",
    "panelledPatientsTextField",
    "dataOptionsFrame"
  ]

  connect() {
    this.updateSelectionDisplay();
  }

  async updateSelectionDisplay() {
    const clinicId = this.exportingClinicTarget.value;

    this.clearSelectedOptions();

    if (!clinicId) return console.warn("No clinic selected. Skipping employee dropdown update.");

    if (this.employeeSelectionRequired()) {
      await this.updateDropdownOptions(
        "employees",
        clinicId,
        this.employeeDropdownOptionsTarget,
        this.setPreviouslySelectedEmployee
      );
    }

    if (this.patientSelectionDropdownTarget.value === "patient_list") {
      await this.updateDropdownOptions(
        "patient_lists",
        clinicId,
        this.patientListDropdownOptionsTarget,
        this.setPreviouslySelectedPatientList
      );
    }

    this.toggleDropdownSections();
  }

  clearSelectedOptions() {
    this.patientListDropdownOptionsTarget.innerHTML = "";
    this.employeeDropdownOptionsTarget.innerHTML = "";
  }

  toggleEmployeeSelectionSection() {
    this.employeeSelectionSectionTarget.hidden = !this.employeeSelectionRequired();
  }

  togglePatientListSelectionSection() {
    if (this.patientSelectionDropdownTarget.value === "patient_list") {
      this.patientListSelectionSectionTarget.hidden = false;
    } else {
      this.patientListSelectionSectionTarget.hidden = true;
    }
  }

  toggleAllOption() {
    switch(this.patientSelectionDropdownTarget.value) {
      case 'all_clinic_patients':
      case 'patient_list':
        this.enable_record_selection_all_option();
        break;
      default:
        this.disable_record_selection_all_option();
    }
  }

  enable_record_selection_all_option() {
    if (this.panelledPatientsSelectionDropdownTarget.value == "filter_by_employee_ids") {
      this.panelledPatientsSelectionDropdownTarget.options.namedItem("all").selected = true;
      this.panelledPatientsTextFieldTarget.value = "";
    }
    this.panelledPatientsSelectionDropdownTarget.options.namedItem("all").disabled = false;
  }

  disable_record_selection_all_option() {
    if (this.panelledPatientsSelectionDropdownTarget.value == "all") {
      this.panelledPatientsSelectionDropdownTarget.options.namedItem("filter_by_employee_ids").selected = true;
    }
    this.panelledPatientsSelectionDropdownTarget.options.namedItem("all").disabled = true;
  }

  employeeSelectionRequired() {
    return ["employee_panelled_patients","employee_clinical_notes_patients", "employee_panelled_or_clinical_notes_patients",].includes(this.patientSelectionDropdownTarget.value);
  }

  shouldShowEmployeeSection() {
    return this.exportingClinicTarget.value && this.employeeSelectionRequired();
  }

  shouldShowPatientListSection() {
    return this.exportingClinicTarget.value && this.patientSelectionDropdownTarget.value === "patient_list";
  }

  toggleDropdownSections() {
    this.toggleEmployeeSelectionSection();
    this.togglePatientListSelectionSection();
    this.toggleAllOption();
  }

  async updateDropdownOptions(endPoint, clinicId, dropdownTarget, previousSelectionSetterCallback) {
    try {
      const response = await fetch(`/admin/export_services/${endPoint}?clinic_id=${clinicId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      })
  
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
  
      const data = await response.json();
  
      dropdownTarget.innerHTML = "";
      this.populateOptions(data, dropdownTarget);
      previousSelectionSetterCallback.call(this)
    } catch (error) {
      console.error(`Error fetching ${endPoint}:`, error);
    }
  }

  populateOptions(data, dropdownTarget) {
    data.map(item => {
      const option = document.createElement("option");
      option.value = item.id;
      option.text = item.name;
      dropdownTarget.add(option);
    })
  }

  updateEmployeeIdTextField() {
    const selectedEmployeeIds = Array.from(this.employeeDropdownOptionsTarget.selectedOptions).map(option => option.value);
    this.panelledPatientsTextFieldTarget.value = selectedEmployeeIds.join(",");
  }

  setPreviouslySelectedEmployee() {
    const previouslySelectedEmployeeIds = this.employeeDropdownOptionsTarget.dataset.selectedEmployeeIds;

    if (!previouslySelectedEmployeeIds) return;

    Array.from(this.employeeDropdownOptionsTarget.options).forEach(option => {
      if (previouslySelectedEmployeeIds.includes(option.value)) {
        option.selected = true;
      }
    })
    this.updateEmployeeIdTextField();
  }

  setPreviouslySelectedPatientList() {
    const previouslySelectedPatientListId = this.patientListDropdownOptionsTarget.dataset.selectedPatientListReportId;

    if (!previouslySelectedPatientListId) return;

    Array.from(this.patientListDropdownOptionsTarget.options).forEach(option => {
      if (option.value === previouslySelectedPatientListId) {
        option.selected = true;
      }
    })
  }

  changeDataOptionsFrame(event) {
    const batchId = this.dataOptionsFrameTarget.dataset.batchId
    const path = this.dataOptionsFrameTarget.dataset.path
    const changedFormat = event.target.value
    const frameLoadListener = () => {
      this.resetFormParts();
      this.dataOptionsFrameTarget.removeEventListener("turbo:frame-load", frameLoadListener);
    };
    
    this.dataOptionsFrameTarget.addEventListener("turbo:frame-load", frameLoadListener)
    this.dataOptionsFrameTarget.src = path + `?batch_id=${batchId}&batch_format=${changedFormat}`
  }

  resetFormParts() {
    this.updateSelectionDisplay();
    alert("You have changed the format which may have reset the data options and selections. Please review.")
  }
}